import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from "@material-ui/core/Typography";
import { transformLink } from '../../../helpers/';
import { graphql } from "gatsby";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CoreParagraphBlock = (props) => {
  // console.log(props);
  const classes = useStyles();

  return <Typography
    variant="body1"
    align={props.attributes.align !== "" ? props.attributes.align : 'left'}
    className={props.attributes.className + ' ' + classes.para}
    data-text-color={props.attributes.textColor !== "" ? props.attributes.textColor : 'initial'}
    data-bg-color={props.attributes.backgroundColor !== "" ? props.attributes.backgroundColor : 'initial'}
  >
    {ReactHtmlParser(props.attributes.content, {transform : transformLink})}
  </Typography>
};

CoreParagraphBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreParagraphBlockFragment = graphql`
fragment CoreParagraphBlock on WpBlock {
  ... on WpCoreParagraphBlock {
    name
    attributes {
      __typename
      ... on WpCoreParagraphBlockAttributes {
        backgroundColor
        textColor
        className
        align
        direction
        content
      }
    }
  }
}`;

export const CoreParagraphBlockPreview = `
... on CoreParagraphBlock {
  name
  attributes {
    __typename
    ... on CoreParagraphBlockAttributes {
      backgroundColor
      textColor
      className
      align
      direction
      content
    }
  }
}
`;